// eslint-disable-next-line
@import 'assets/scss/style.scss';
@import 'assets/scss/settings/custom-variables';

@import 'react-date-range/dist/styles.css'; // main css file
@import 'react-date-range/dist/theme/default.css'; // theme css file

/*.pcoded-navbar.mob-open ~ .pcoded-header:before,
.pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header:before{
  display: none;
}

.nav-outside.mob-backdrop ~ .pcoded-main-container:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0,0,0,0.25);
}
.pcoded-header .dropdown .notification .noti-body li {
  width: 100%;
}

.header-chat .h-list-footer .input-group .btn-attach {
  padding: 0;
  width: 35px;
  height: 35px;
}

.header-chat .h-list-footer .input-group .btn-send {
  top: 7px;
}*/

.pcoded-navbar {

  &.mob-open~.pcoded-header:before,
  &.navbar-collapsed:hover~.pcoded-header:before {
    display: none;
  }
}

.nav-outside.mob-backdrop~.pcoded-main-container:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-header .dropdown .notification .noti-body li {
  width: 100%;
}

.header-chat .h-list-footer .input-group {
  .btn-attach {
    padding: 0;
    width: 35px;
    height: 35px;
  }

  .btn-send {
    top: 7px;
  }
}

@media only screen and (max-width: 991px) {
  /*

  .nav-outside ~ .pcoded-header,
  .nav-outside ~ .pcoded-main-container {
    margin-left: 0;
  }

  .nav-outside.mob-fixed ~ .pcoded-main-container {
    padding-top: 56px;
  }

  .pcoded-header .container>.collapse:not(.show) .mr-auto, .pcoded-header>.collapse:not(.show) .mr-auto {
    display: inline-flex;
    justify-content: center;
  }

  */

  .nav-outside {
    ~ {

      .pcoded-header,
      .pcoded-main-container {
        margin-left: 0;
      }
    }

    &.mob-fixed~.pcoded-main-container {
      padding-top: 56px;
    }

    .pcoded-header {

      .container>.collapse:not(.show) .mr-auto,
      >.collapse:not(.show) .mr-auto {
        display: inline-flex;
        justify-content: center;
      }
    }
  }
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

.zoom-container {
  position: relative;
  display: flex;
}

.img-preview-section-container {
  display: none;
}

.zoom-container:hover .img-preview-section-container {
  display: block;
  position: relative;
  left: 50px;
}

.zoom-container:hover .img-preview-section-container {
  left: 100%;
  position: absolute;
  background: #fff;
  border: 1px solid $border-color;
}

.carousel-inner {
  overflow: visible;
  z-index: 1000;
}

.form-control[type='file'] {
  height: auto;
  padding-left: 0.45rem;
}

.table {
  .badge {
    margin-right: 0.25rem;
  }
}

.mb-3.row {

  >.col,
  .col.d-flex {
    @media (max-width: 791px) {
      margin-top: 5px;
      margin-bottom: 5px;

      &:nth-child(2) {
        flex-direction: column;
        text-align: left;

        >span {
          justify-content: flex-start !important;

          .form-control {
            max-width: 150px;
          }
        }

        .btn {
          margin-top: 8px;
          max-width: fit-content;
          margin-left: 0 !important;
        }
      }
    }
  }
}

.row.justify-content-between>.col>.d-flex.align-items-center strong {
  margin: 0 5px;
}

@media (max-width: 575px) {

  .row.justify-content-between>*[class*='col-'],
  .row.justify-content-between>.col {
    flex: 100%;

    .pagination {
      justify-content: flex-start !important;
    }

    &:first-child {
      margin-bottom: 8px;
    }

    >.d-flex.align-items-center {
      input {
        width: 40px !important;
      }
    }
  }

  .photoshop-picker-sm {
    display: none !important;
  }
}

td {
  img.wid-40 {
    max-width: 40px;
  }
}

.new-cust-card {
  @media (max-width: 1024px) {
    .d-inline-block {
      width: calc(100% - 55px);
    }

    .align-middle .status {
      position: relative;
      top: 0;
      left: 0;
    }
  }
}

.table th,
.table td {
  vertical-align: middle;
}

.cover-img-block {
  overflow: hidden;
  z-index: 1;
}

.user-about-block {
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 1200px) {
  .user-card .user-about-block {
    margin-top: -10px;
  }
}

.alert {
  .float-right {
    margin-left: 8px;
    float: none !important;
  }
}

@media (max-width: 768px) {
  .modal-open .modal {
    padding-left: 0 !important;
  }
}

.carousel.slide {
  overflow: hidden;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  left: 18px !important;
}

.smooth-dnd-container.horizontal {
  button {
    border: none;
  }
}

.ui-pnotify-icon {
  >span {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 21px;
    height: 21px;
  }
}

.table.table-bordered.table-hover {
  th {
    select.form-control {
      min-width: 140px;
    }
  }
}

.filter-bar .navbar {
  @media (max-width: 690px) {
    padding: 0.5rem 1rem 1rem;

    #dropdown-basic {
      padding-left: 10px;
    }

    .f-view {
      padding-left: 15px;
      margin-top: 8px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-gray {
  color: #abb2b9 !important;
}

.card-filter {
  width: 150px !important;
  height: 50px !important;
}

.h300 {
  height: 280px;
  overflow: auto;
}

.container_filtros {
  display: flex;
  /*max-width: 650px;*/
  /*overflow: auto;*/
  height: 45px;
  border-radius: 8px !important;
  margin-right: 15px;
}

.backgroun-white {
  display: flex;
  justify-content: space-around;
  background-color: white;
  font-size: 12px;
  margin-right: 4px;
  position: relative;
  margin-left: 5px;
  height: 50px;
  margin-top: 5px;
  border-radius: 20px;
  border: 2px transparent;
}

.dropdown-toggle::after {
  display: none !important;
}

.card_around {
  display: flex;
  justify-content: space-around;
  background-color: white;
  font-size: 12px;
  margin-right: 4px;
  position: relative;
  margin-left: 5px;
  height: 50px;
  margin-top: 5px;
  border-radius: 20px;
  border: 2px transparent;
}

.card_around.active,
.card_around:hover {
  border: 1px solid #213f8f;
}

.card_around.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-top: 8px solid #213f8f;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  bottom: -15px;
  left: calc(50% - 7px);
}

.selectbox {
  width: 50%;
  margin: auto;
  position: relative;
}

.opciones {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, .16);
  overflow: hidden;
}

.filters {
  overflow: hidden;
  width: 80px;
  height: 50px;
  margin-left: 15px;
  margin-top: 5px;
  cursor: pointer;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: red;
}

.container_filtros::-webkit-scrollbar {
  background: #F2F4F4;
}

.container_filtros::-webkit-scrollbar:vertical {
  width: 7px;
}

.container_filtros::-webkit-scrollbar:horizontal {
  width: 7px;
}

.container_filtros::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .3);
  border-radius: 99999px;
  border: .3125em solid transparent;
  background-clip: content-box;
}

table td,
table th {
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: #d4d6d7 solid 1px;
  padding: 0.5rem;
}

.table thead th {
  font-size: 1.25rem;
}

.containerbuttonShowHide {
  position: fixed;
  right: 0;
  top: 200px;
  transition: .15s ease-in-out;
  z-index: 1031;
}

.buttonShowHideFilters {
  background: linear-gradient(90deg, #ff9701, #ff9701);
  border-radius: 30px 0 0 30px;
  box-shadow: -4px 0 8px rgba(255, 182, 77, .16), -6px 0 8px rgba(0, 0, 0, .1);
  border: 1px white;
  height: 45px;
  position: absolute;
  right: 0;
  top: 0;
  transition: .15s ease-in-out;
  width: 45px;
  z-index: 1001;
}


.relative {
  position: relative;
}

.icon-close {
  position: relative;
  left: -15px;
  cursor: pointer;
  color: red;
}

.f12 {
  font-size: 13px;
}

.f13 {
  margin-bottom: 2px;
  font-size: 13px;
}


/*BUTTON DROP DOWN*/
.button-60 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: .375em;
  box-shadow: none;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  height: 2.5em;
  justify-content: center;
  line-height: 1.5;
  padding: calc(.5em - 1px) 1em;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-60:active {
  border-color: #4a4a4a;
  outline: 0;
}

.button-60:focus {
  border-color: #485fc7;
  outline: 0;
}

.button-60:hover {
  border-color: #b5b5b5;
}

.button-60:focus:not(:active) {
  box-shadow: rgba(72, 95, 199, .25) 0 0 0 .125em;
}

.bc-20 {
  background: #daf08c !important;
}

.bc-40 {
  background: #a7da6a !important;
}

.bc-60 {
  background: #66be63 !important;
}

.bc-80 {
  background: #13994f !important;
  color: white !important;
}

.bc-100 {
  background: #006834 !important;
  color: white !important;
}

.bc-15 {
  background: #fefe32 !important;
}

.bc-10 {
  background: #fafa79 !important;
}

.bc-5 {
  background: #ffe987 !important;
}

.bc-0 {
  background: #fff7bd !important;
}

.bc-0-20 {
  background: #ff8b65 !important;
}

.bc-0-40 {
  background: #fb6a49 !important;
}

.bc-0-60 {
  background: #ef3828 !important;
  color: white !important;
}

.bc-0-80 {
  background: #d82c22 !important;
  color: white !important;
}

.bc-0-100 {
  background: #800000;
  color: white;
}

.custom-tr {
  font-size: 12px;
}

.custom-tr td {
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.br-grey {
  border-right: solid 1px #cccccc;
  padding-right: 1.3rem;
}

.fz10 {
  font-size: 11px !important;
  //background-color: #abb2b9;
}

.fz12{
  font-size: 12px !important;
}

.apexcharts-tooltip {
  color: #000;
  transform: translateX(2px) translateY(5px);
}

.arrow_box {
  transform: translateX(-5px);
  height: 56px !important;
}

.tooltipcustom {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltipcustom .tooltiptextcustom {
  top: 5px;
  left: 107%;
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltipcustom:hover .tooltiptextcustom {
  visibility: visible;
}

.custom-table {
  min-width: 900px !important;
  font-size: 11px !important;
}

.custom-table thead tr,
.custom-table thead th {
  border-top: none !important;
  border-bottom: none !important;
}

.custom-table tbody th,
.custom-table tbody td {
  font-weight: 400 !important;
  padding-bottom: 6px !important;
  padding-top: 6px !important;
  font-weight: 300 !important;
}

.custom-table tbody th small,
.custom-table tbody td small {
  color: #b3b3b3 !important;
  font-weight: 300 !important;
}

.custom-table tbody .persons {
  padding: 0 !important;
  margin: 0 !important;
}

.custom-table tbody .persons li {
  padding: 0 !important;
  margin: 0 0 0 -15px !important;
  list-style: none !important;
  display: inline-block !important;
}

.custom-table tbody .persons li a {
  display: inline-block !important;
  width: 36px !important;
}

.custom-table tbody .persons li a img {
  border-radius: 50% !important;
  max-width: 100% !important;
}

.custom-table tbody tr th,
.custom-table tbody tr td {
  position: relative !important;
  -webkit-transition: .3s all ease !important;
  -o-transition: .3s all ease !important;
  transition: .3s all ease !important;
}

.custom-table tbody tr th:before,
.custom-table tbody tr th:after,
.custom-table tbody tr td:before,
.custom-table tbody tr td:after {
  -webkit-transition: .3s all ease !important;
  -o-transition: .3s all ease !important;
  transition: .3s all ease !important;
  content: "" !important;
  left: 0 !important;
  right: 0 !important;
  position: absolute !important;
  height: 1px !important;
  background: #bfbfbf !important;
  width: 100% !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

.custom-table tbody tr th:before,
.custom-table tbody tr td:before {
  top: -1px !important;
}

.custom-table tbody tr th:after,
.custom-table tbody tr td:after {
  bottom: -1px !important;
}

.custom-table tbody tr:hover th,
.custom-table tbody tr:hover td {
  background: #EAECEE !important;
  color: black !important;
}

.custom-table tbody tr:hover th:before,
.custom-table tbody tr:hover th:after,
.custom-table tbody tr:hover td:before,
.custom-table tbody tr:hover td:after {
  opacity: 1 !important;
  visibility: visible !important;
}

.custom-table tbody tr.active th,
.custom-table tbody tr.active td {
  background: rgba(0, 0, 0, 0.03) !important;
}

.custom-table tbody tr.active th:before,
.custom-table tbody tr.active th:after,
.custom-table tbody tr.active td:before,
.custom-table tbody tr.active td:after {
  opacity: 1 !important;
  visibility: visible !important;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: rgba(64, 153, 255, 0.03) !important;
}

.customLabels {
  font-size: 12px !important;
  color: #545454 !important;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.tableWrap{
  table-layout: fixed !important;
}

/*.tableWrap th,td{
  word-wrap: break-word !important;
  white-space: normal !important;
}*/

.tdWrap{
  word-wrap: break-word !important;
  white-space: normal !important;
}
.highcharts-credits{
  display: none;
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(1.1);
  }

  100% {
      transform: scale(1);
  }
}

.custom-pulse {
  border-radius: 50%;
  animation: pulse 2s infinite;
}